import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import HeroImage from '../components/hero/heroImage';
import TeamDetail from '../components/team/teamDetail'
import Section from '../components/layout/section';



const Team = ({location,...props}) => {
    const person = get(props,'data.contentfulPerson')
    console.log('modal',props)

    return (  
        <Section>
          
   <div className="container--narrow">
   <TeamDetail data={person}/>
  
          <Link className="back" to="/team-und-partner">Das ganze Team ansehen</Link>
        </div>
        </Section>
    
     
    );
}

export default Team;

export const pageQuery = graphql`
  query PersonBySlug($slug: String!) {
    contentfulPerson(slug: { eq: $slug }) {
      name
      email
      linkedIn
      imageDetail {
        fluid(maxWidth: 1180,quality: 90, background: "rgb:000000") {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      shortBio {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`